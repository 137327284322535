import React from "react"
import styled from "styled-components"
import Resources from "../../resources/afisofom-resources"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'
import { useSiteContent } from "../hooks/use-site-content"

const MissionAndVissionWrapper = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;
  margin-left: -50%;
  width: 150%;
  height: auto;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 100%;
  }
`

const MissionAndVissionImageContainer = styled.div`
  margin-left: 25%;

  @media only screen and (max-width: 1720px) {
    div {
      border-radius: 0 300px 300px 0;
    }
  }

  @media only screen and (min-width: 1721px) {
    div {
      border-radius: 300px;
    }
  }

  .img-container {
    min-width: 100%;
    margin: 60px 0;
    z-index: -10;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: none;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-left: 0;

    .img-container {
      padding: 0;
    }
  }
`

const MissionAndVisionTextContainer = styled.div`
  padding: 0 60px 60px 60px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding: 0 30px 30px 30px;
    padding-left: 35%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 0 30px 30px 30px;
  }
`

const MissionAndVissionTitle = styled.h1`
  padding-top: 60px;
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 991px) {
    padding-top:30px;
  }
  
  font-family: var(${Resources.mission_vision.title.font});
  font-size: ${Resources.mission_vision.title.size};
  color: var(${Resources.mission_vision.title.color});

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.mission_vision.title.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.mission_vision.title.size_med};
  }
`

const MissionAndVissionSubtitle = styled.p`
  font-family: var(${Resources.mission_vision.subtitle.font});
  font-size: ${Resources.mission_vision.subtitle.size};
  color: var(${Resources.mission_vision.subtitle.color});
  line-height: ${Resources.mission_vision.subtitle.line_height};

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.mission_vision.subtitle.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.mission_vision.subtitle.size_med};
  }
`

const MissionAndVission = () => {
  const siteContent = useSiteContent();
  return (
    <StaticQuery
      query={graphql`
      query {
        img: file(name: { eq: "mission_vision_image" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => (
        <div className="container">
          <MissionAndVissionWrapper>
            <MissionAndVissionImageContainer>
              <Img

                fluid={data.img.childImageSharp.fluid}
                imgStyle={{ objectFit: "contain", width: "100%", height: "100%" }}
                className="img-container"
              />
            </MissionAndVissionImageContainer>
            <Fade big>
              <MissionAndVisionTextContainer>
                <MissionAndVissionTitle>
                  {Resources.mission_vision.title_1}
                </MissionAndVissionTitle>
                <MissionAndVissionSubtitle>
                  {siteContent.mission_vision.mission}
                </MissionAndVissionSubtitle>
                <MissionAndVissionTitle>
                  {Resources.mission_vision.title_2}
                </MissionAndVissionTitle>
                <MissionAndVissionSubtitle>
                  {siteContent.mission_vision.vision}
                </MissionAndVissionSubtitle>
              </MissionAndVisionTextContainer>
            </Fade>
          </MissionAndVissionWrapper>
        </div>
      )}
    />
  )
}

export default MissionAndVission
