import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from "styled-components"
import fetch from "cross-fetch"
import Resources from "../../resources/afisofom-resources"

/**
 * HeaderWrapper element, used to set style to a component.
 */
const IndicatorsWrapper = styled.div`
  position: relative;
  z-index: 10 !important;
  bottom: 30px;
  background-color: var(${Resources.indicators.bg_opacity});
  height: 30px;
  width: 100vw;
  overflow: hidden;
  font-family: var(${Resources.indicators.font});

  span {
    margin: auto 0;
    align-self: center;
  }
`

const IndicatorsInner = styled.div`
  display: inline-block;

  z-index: 99999 !important;
  margin: 0;
  height: 30px;
  padding-top: 4px;
  box-sizing: content-box;
  white-space: nowrap;
  padding-right: 100%;
  position: relative;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 500s;
  animation-duration: 500s;
  animation-play-state: paused;
  outline: 1px solid transparent;

  @keyframes ticker {
    from {
      /* left: 0; */
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
      /* left: -197800px; */
    }
  }
`

const IndicatorsContainer = styled.div`
  margin-bottom: 60px;
  height: 30px;
  width: 100%;
  overflow: hidden;
  padding-left: 100%;
  box-sizing: content-box;
  outline: 1px solid transparent;
`

const IndicatorsQuote = styled.div`
  display: inline-block;
  height: 30px;
  align-content: center;
  margin-right: 24px;
  outline: 1px solid transparent;
`

const IndicatorsShortName = styled.span`
  font-weight: bold;
  color: ${Resources.indicators.quote_color};
`

const IndicatorsValue = styled.span`
  color: var(${Resources.indicators.value_color});
`

class Indicators extends Component {
  constructor() {
    super()
    this.state = { 
      indicators: [],
      offsetTop: 0
    }
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.collectIndicators();
    const indicators = document.querySelector('#indicators');
    this.setState({
      offsetTop: indicators.offsetTop
    });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll)
  }

  /**
   * Handle scroll method
   */
  handleScroll = () => {
    const indicators = document.querySelector('#indicators')
    const navbar = document.querySelector('#navbar-top')
    if((window.pageYOffset + navbar.offsetHeight) >= this.state.offsetTop) {
      indicators.classList.add('sticky-menu') 
      indicators.style.top = `${navbar.offsetHeight}px`
    }
    else {
      indicators.classList.remove('sticky-menu')
      indicators.style.top = null
    }
  }

  /**
   * Getting the shortname series of Banxico's data.
   * @param {*} idSerie 
   */
  shortName = idSerie => {
    switch (idSerie) {
      case "SP74660":
        return "Inflación Mensual"
      case "SF43783":
        return "Tasas de interés, TIIE28 (Diario)"
      case "SF283":
        return "Tasas de interés, TIIE28 (Mensual)"
      case "SP74661":
        return "Inflación acumulada en el año"
      case "SP74662":
        return "Inflación anual"
      case "SF43718":
        return "Tipo de cambio en dólares (FIX)"
      case "SF282":
        return "Tasas de interés, CETES28"
      default:
        return ""
    }
  }

  collectIndicators = async () => {
    const response = await fetch(
      "https://afi-configuration-bucket.s3.us-east-2.amazonaws.com/indicators.json"
    )

    const data = await response.json()

    const array = []
    const keys = Object.keys(data)
    for (let i = 50; i >= 0; i--) {
      for (const key of keys) {
        data[key].shortName = this.shortName(data[key].idSerie)
        array.push(data[key])
      }
    }

    this.setState(
      {
        indicators: array,
      },
      () => {
        let elem = document.getElementsByClassName("indicators-inner")[0]
        setTimeout(() => {
          elem.style = "animation-play-state: running"
        }, 100)
      }
    )
    return []
  }

  render() {
    return (
      <IndicatorsWrapper id={'indicators'}>
        <IndicatorsContainer>
          <IndicatorsInner className={"indicators-inner"}>
            {this.state.indicators.map((quote, index) => (
              <IndicatorsQuote key={quote.idSerie + "_" + index}>
                <IndicatorsShortName>{quote.shortName}: </IndicatorsShortName>
                <IndicatorsValue>
                  {Math.abs(
                    parseFloat(quote.datos[0].dato)
                  ).toLocaleString(navigator.language, {
                    maximumFractionDigits: 5,
                  })}
                </IndicatorsValue>
              </IndicatorsQuote>
            ))}
          </IndicatorsInner>
        </IndicatorsContainer>
      </IndicatorsWrapper>
    )
  }
}
Indicators.propTypes = {
  location: PropTypes.string,
}

Indicators.defaultProps = {
  location: `/`,
}

export default Indicators
