import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useSiteContent } from "../hooks/use-site-content"
import Resources from "../../resources/afisofom-resources"
import { graphql, navigate, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import CustomButton from "./button"
import Indicators from "./indicators"
import Fade from "react-reveal/Fade"

import CoverSquare from "../images/svg/cover-square.svg"

const CoverWrapper = styled.div`
  z-index: 100002;
  margin-top: -80px;
  --min-height-med: calc(100vh);
  --min-height-big: calc(100vh);

  @media only screen and (min-width: 992px) {
    height: var(--min-height-med);
    overflow: hidden;
  }

  @media only screen and (max-width: 991px) {
    height: var(--min-height-med);
    overflow: hidden;
  }
`

const CoverBackgroundSection = styled.div`
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: auto;
  opacity: ${Resources.cover.bg_opacity} !important;
  background-color: var(${Resources.cover.bg_color}) !important;

  @media only screen and (max-width: 991px) {
    height: var(--min-height-med);
  }
`

const CoverInfoSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  height: auto;
  padding-top: 80px;
`

const CoverContentContainer = styled.div`
  height: calc(100vh - 80px);
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  @media only screen and (max-width: 991px) {
    --current-max-w: 100vw !important;
    padding: 0 !important;
  }
`

const CoverContentSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7vw;

  width: 100%;
  height: calc(100vh - 80px);

  @media only screen and (max-width: 991px) {
    overflow: hidden;
    grid-template-columns: 1fr;
  }
`

const CoverImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 30px;
  width: 100%;
  padding-left: calc((100vw - var(--current-max-w)) / 2);

  @media only screen and (max-width: 479px) {
    width: 90%;
    margin-left: 48%;
    margin-top: -5%;
  }

  @media only screen and (min-width: 480px) and (max-width: 991px) {
    width: 70%;
    margin-left: 50%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
`

const CoverTextContainer = styled.div`
  display: block;
  width: 100%;
  margin: auto 0;

  @media only screen and (max-width: 991px) {
    width: 70%;
    margin: auto auto;
  }
`

const CoverTextDisplay = styled.div`
  text-align: left;
  margin: 20px 0;

  .cover-title {
    font-family: var(${Resources.cover.title.font});
    font-size: ${Resources.cover.title.size};
    color: var(${Resources.cover.title.color});
    text-transform: uppercase;
    line-height: normal;
  }

  .cover-subtitle {
    font-family: var(${Resources.cover.subtitle.font});
    font-size: ${Resources.cover.subtitle.size};
    color: var(${Resources.cover.subtitle.color});
    line-height: ${Resources.cover.subtitle.line_height};
  }

  @media only screen and (max-width: 479px) {
    text-align: center;

    .cover-title {
      font-size: ${Resources.cover.title.size_small};
    }

    .cover-subtitle {
      font-size: ${Resources.cover.subtitle.size_small};
    }
  }

  @media only screen and (min-width: 480px) and (max-width: 991px) {
    text-align: center;

    .cover-title {
      font-size: ${Resources.cover.title.size_med};
    }

    .cover-subtitle {
      font-size: ${Resources.cover.subtitle.size_med};
    }
  }
`

const CoverSquareStyled = styled(CoverSquare)`
  position: absolute;
  top: -180px;
  @media only screen and (min-width: 1150px) {
    left: -130px;
  }

  @media only screen and (min-width: 1050px) and (max-width: 1149px) {
    left: -180px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1049px) {
    left: -200px;
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }
`

const DisplayBadgesContainer = styled.div`
  position: fixed !important;
  top: 0;
  right: 20px;
  height: auto;
  z-index: 200;
  display: flex;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};

  @media only screen and (max-width: 991px) {
    right: auto;
    left: calc(50% - 84px);
  }
`

const CoverGPTW = styled(Img)`
  width: 100px;
  height: 170px;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};

  @media only screen and (max-width: 991px) {
    width: 60px;
    height: 96px;
  }
`
const CoverESR = styled(Img)`
  width: 100px;
  height: 100px;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};

  @media only screen and (max-width: 991px) {
    width: 60px;
    height: 60px;
  }
`

const CoverISO = styled(Img)`
  width: 130px;
  height: 129px;
  transition: opacity 0.5s;
  opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};

  @media only screen and (max-width: 991px) {
    width: 60px;
    height: 59px;
  }
`

const Cover = () => {
  const siteContent = useSiteContent()
  const [scrolled, setScrolled] = useState(false)

  const goToBottom = () => {
    window.scrollTo(
      0,
      document?.querySelector("#note-footer")?.getBoundingClientRect()?.y - 130
    )
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const { scrollY } = window
      if (scrollY >= 100) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    })
  }, [])
  return (
    <StaticQuery
      query={graphql`
        query {
          mainImage: file(name: { eq: "cover_background" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          iso: file(name: { eq: "iso" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 300) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          gptw: file(name: { eq: "gptw" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          esr: file(name: { eq: "esr" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 150) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <CoverWrapper>
            <DisplayBadgesContainer>
              <CoverISO
                scrolled={scrolled}
                fluid={data.iso.childImageSharp.fluid}
              />
              <CoverESR
                scrolled={scrolled}
                fluid={data.esr.childImageSharp.fluid}
              />
              <CoverGPTW
                scrolled={scrolled}
                fluid={data.gptw.childImageSharp.fluid}
              />
            </DisplayBadgesContainer>
            <CoverBackgroundSection />
            <CoverSquareStyled />
            <CoverInfoSection>
              <Fade big>
                <CoverContentContainer className="container">
                  <CoverContentSection>
                    <CoverTextContainer>
                      <CoverTextDisplay>
                        <span className="cover-title">
                          {siteContent.cover.title}
                        </span>
                        <span
                          className="cover-title"
                          style={{
                            cursor: "pointer",
                            color: "var(--navLinkColor)",
                          }}
                          onClick={goToBottom}
                        >
                          *
                        </span>
                      </CoverTextDisplay>
                      <CoverTextDisplay>
                        <span className="cover-subtitle">
                          {siteContent.cover.subtitle}
                        </span>
                      </CoverTextDisplay>

                      <CoverTextDisplay>
                        <CustomButton
                          label={Resources.cover.button.label}
                          view={Resources.cover.button.view}
                          fullWidth={false}
                          containerStyle={{ marginTop: "37.5px" }}
                          onClick={() =>
                            navigate(
                              "https://clientes.afisofom.com/afisofom/solicitud"
                            )
                          }
                        />
                      </CoverTextDisplay>
                    </CoverTextContainer>
                    <CoverImageContainer>
                      <Img
                        fluid={data.mainImage.childImageSharp.fluid}
                        imgStyle={{
                          objectFit: "contain",
                          width: "100%",
                          height: "auto",
                        }}
                        style={{ minWidth: "100%" }}
                      />
                    </CoverImageContainer>
                  </CoverContentSection>
                </CoverContentContainer>
              </Fade>
              <Indicators />
            </CoverInfoSection>
          </CoverWrapper>
        )
      }}
    />
  )
}

export default Cover
