import React, { Component } from "react"
import styled from "styled-components"
import Resources from "../../resources/afisofom-resources"
import ServiceItem from "./service-item"
import Fade from 'react-reveal/Fade'
import { useSiteContent } from "../hooks/use-site-content"

const ServConf = Resources.services;

const ServicesSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2em;
  margin-top: 40px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const ServicesSectionTitle = styled.label`
  font-family: var(${Resources.services.title.font});
  color: var(${Resources.services.title.color});
  font-size: ${Resources.services.title.size};
  text-transform: uppercase;
  font-size: var(--section-title-size);
`
// POPUPS
const PopupHolder = styled.div`
`

const BGDiv = styled.div`
  background-color: var(--main-color-text);
  filter: opacity(80%);
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
`

const PopupDiv = styled.div`
  position: fixed;
  width: 37.5vw; 
  top: 20vh;
  left: 27.5vw;
  background-color: var(--white-color);
  border-radius: 20px;
  padding: 3em;
  z-index: 10001;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    min-width: 66%;
    max-width: 66%;
    margin: 0 auto;
    left: auto;
    min-height: 350px;
    max-height: 350px;
    overflow-y: auto;
    padding: 30px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin: 0 auto;
    min-height: 400px;
    max-height: 400px;
    padding: 30px;
  }
`
const PopupTitle = styled.h2`
  font-family: var(--font-bold);
  margin-bottom: 2em;
`

const PopupText = styled.p`
  line-height: 1.8em;
  margin-bottom: 5em;

  .accent-text {
    font-family: var(--font-bold);
  }
`

class ServicesSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBox: false,
      textItem: "",
      popupTitle: "",
      popupText: ""
    }
  }
  
  toggleBox(textName, services) {
    // Set state and call TextPopup with the callback function
    this.setState({
      showBox: !this.state.showBox,
      textItem: textName,
    }, function() {
      this.TextPopup(services)
    })
  }

  TextPopup (services) {
    if(this.state.textItem) {
      this.setState({
        popupTitle: ServConf[this.state.textItem]?.title,
        popupText: services[this.state.textItem]
      })
    }
    if (this.state.showBox) {
      document.body.style =
        "overflow-y:hidden; position: relative; margin-right: var(--widthReflow);"
    } else {
      document.body.style =
        "overflow-y:visible; position: static; margin-right: 0;"
    }
  }

  render() {
    const services = this.props.siteContent.services;
    return (
      <div className="container" id="servicios">
        {this.state.showBox ? (
          <PopupHolder onClick={() => this.toggleBox()}>
            <PopupDiv>
              <PopupTitle>{this.state.popupTitle}</PopupTitle>
              <PopupText dangerouslySetInnerHTML={{__html: this.state.popupText}}/>
            </PopupDiv>
            <BGDiv />
          </PopupHolder>
        ) : null}
        <Fade up>
        <ServicesSectionTitle>
          {Resources.services.title.label}
        </ServicesSectionTitle>
        <ServicesSectionWrapper>
          <ServiceItem type="contract" onClick={() => this.toggleBox("contract", services)} />
          <ServiceItem type="businessman" onClick={() => this.toggleBox("businessman", services)} />
          <ServiceItem type="bank" onClick={() => this.toggleBox("bank", services)} />
          <ServiceItem type="copy" onClick={() => this.toggleBox("copy", services)} />
          <ServiceItem type="briefcase" onClick={() => this.toggleBox("briefcase", services)} />
        </ServicesSectionWrapper>
        </Fade>
      </div>
    )
  }
}

export default props => {
  const SiteContent = useSiteContent();
  return (
    <ServicesSection siteContent={SiteContent} {...props} />
  )
}

