import React, { Component } from "react";
import styled from "styled-components";
import Button from "./button";
import Fade from 'react-reveal/Fade'
import Resources from "../../resources/afisofom-resources.json";

const ContactConf = Resources.contactanos;

const ContactWrapper = styled.div `
    background-color: var(--main-color-2);
    
    display: grid;
    align-items: center;
    justify-items: center;
    
    padding: 4em;

    @media only screen and (min-width: 768px) {
        padding: 4em;
    }

    @media only screen and (max-width: 767px) {
        padding: 30px 10px;
    }
`

const StyledTitle = styled.h1 `
    font-family: var(--font-bold);
    color: var(--main-background);
    margin: 0;
    font-size: var(--section-title-size);
`

const StyledText = styled.p `
    font-family: var(--font-bold);
    color: var(--main-background);
    max-width: 36vw;
    text-align: center;

    /* Medium devices (landscape tablets, 768px and under) */
    @media only screen and (max-width: 768px) {
        max-width: 100vw;
    }
`

class Contactenos extends Component {

    render() {
        return (
            <ContactWrapper>
                <Fade big>
                <StyledTitle>{ContactConf.titulo}</StyledTitle>
                <StyledText>{ContactConf.texto}</StyledText>
                <Button label={ContactConf.button_1.label}
                        view={ContactConf.button_1.view}
                        onClick={() => onLinkClick("contact-form-id")}
                        />
                </Fade>
            </ContactWrapper>
        );
    }

}

const onLinkClick = (to) => {
    const element = document.getElementById(to);
    if (element) {
      const position = element.offsetTop - Resources.navbar.offset_top;
      window.scrollTo(0, position);
    }
  };

export default Contactenos;
