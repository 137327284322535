import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { useState } from "react"
import styled from "styled-components"

/** Fixed modal with bg-opacity 60 and centered container */
const LegalModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`

/** Modal container with white bg and padding */
const LegalModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
  max-width: 500px;
  max-height: 100vh;
  text-align: center;
`

export const LegalModal = () => {
  // Modal shown just on the main page every time the user enters the site
  const [showModal, setShowModal] = useState(true)

  return (
    <StaticQuery
      query={graphql`
        query {
          img: file(relativePath: { eq: "main-logo.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <>
          {showModal && (
            <LegalModalWrapper>
              <LegalModalContainer>
                <h2>Advertencia Importante a Nuestros Usuarios</h2>
                <p>
                  Queremos informarles que actualmente no disponemos de ninguna
                  aplicación móvil asociada a nuestro sitio web. Les pedimos que
                  se mantengan alerta y no descarguen ni utilicen ninguna
                  aplicación que se haga pasar por Afisofom o alguno de los
                  servicios ofrecidos a nombre de Análisis, Filtro, e Indices
                  SAPI de C.V. SOFOM, E.N.R.
                </p>
                <p>
                  Estas podrían ser intentos de suplantación de identidad. Si
                  encuentran alguna aplicación que pretenda estar asociada con
                  nosotros, por favor, no proporcionen ningún tipo de
                  información personal y repórtenlo de inmediato a nuestro
                  equipo de soporte al usuario a través del medio de contacto
                  proporcionado por este sitio web. Agradecemos su atención y
                  les reiteramos nuestro compromiso con su seguridad en línea.
                </p>
                <button onClick={() => setShowModal(false)}>Aceptar</button>
              </LegalModalContainer>
            </LegalModalWrapper>
          )}
        </>
      )}
    />
  )
}
