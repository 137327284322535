import React from "react"
import styled from "styled-components"
import Resources from "../../resources/afisofom-resources.json"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const MainItemHolding = styled.div`
  width: 100%;
  height: 100%;

  .contentCard {
    background-color: var(${Resources.we_are_part.bg_color_holding}) !important;
    position: absolute;
    width: 33vh;
    height: 33vh;
    will-change: transform, opacity;
    padding: 2em;
    align-items: center;

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
      width: 38vh;
      height: 38vh;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 38vh;
      height: 38vh;
    }
  }
`

const ItemHoldingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  justify-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: grid;
    grid-gap: 1em;
    justify-content: normal;
    align-items: center;
    justify-items: center;
  }
`

const ItemHoldingImgWrapper = styled.div`
  /*width: 100px;
    height: 100px;
    padding: 3em;*/
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
  }
`


const ItemHolding = () => (
  <StaticQuery
    query={graphql`
      query {
        holding: file(relativePath: { eq: "logo-holding.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <MainItemHolding>
          <ItemHoldingWrapper>
            <div className="contentCard">
              <ItemHoldingImgWrapper>
                <Img
                  fluid={data.holding.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </ItemHoldingImgWrapper>
            </div>
          </ItemHoldingWrapper>
        </MainItemHolding>
      )
    }}
  />
)

export default ItemHolding
