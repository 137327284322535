import React from "react"
import styled from "styled-components"
import Resources from "../../resources/afisofom-resources"
import PropTypes from 'prop-types'
import BankImage from '../images/services/bank.svg'
import BriefcaseImage from '../images/services/briefcase.svg'
import BusinessmanImage from '../images/services/businessman.svg'
import ContractImage from '../images/services/contract.svg'
import CopyImage from '../images/services/copy.svg'

const ServiceItemWrapper = styled.div`
  width: 100%;
  height: auto;
  display: block;
  
  :hover {
    cursor:pointer;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
    grid-gap: 1em;
    justify-content: flex-start;
    align-items: center;
  }
`;

const ServiceItemImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  width: 100%;
  height: 100px;
  margin-bottom: 30px;
  
  .img {
    max-height: 100%;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    height: 90px;
    width: 100px;
    
    .img {
      max-height: 100%;
    }
  }
`;

const ServiceItemTextWrapper = styled.div`
  font-family: var(${Resources.services.font});
  font-size: ${Resources.services.size};
  color: var(${Resources.services.color});
  text-align: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    text-align: left;
  }
  
  .accent-text {
    color: var(${Resources.services.title.color}) !important;
    font-weight: bold !important;
  }
`;

const ServiceItem = ({ type, onClick }) => {
  let image, text;

  switch (type) {
    case 'briefcase':
      image = <BriefcaseImage className="img" />;
      text = Resources.services.briefcase.title;
      break;
    case 'businessman':
      image = <BusinessmanImage className="img" />;
      text = Resources.services.businessman.title;
      break;
    case 'contract':
      image = <ContractImage className="img" />;
      text = Resources.services.contract.title;
      break;
    case 'copy':
      image = <CopyImage className="img" />;
      text = Resources.services.copy.title;
      break;
    default:
      image = <BankImage className="img" />;
      text = Resources.services.bank.title;
      break;
  }

  return (
    <ServiceItemWrapper className="svg-icon" onClick={onClick}>
      <ServiceItemImgWrapper>
        {image}
      </ServiceItemImgWrapper>
      <ServiceItemTextWrapper>{text}</ServiceItemTextWrapper>
    </ServiceItemWrapper>
  );
};

ServiceItem.propTypes = {
  type: PropTypes.oneOf([
    'bank',
    'briefcase',
    'businessman',
    'contract',
    'copy'
  ]),
  onClick: PropTypes.func.isRequired
};

ServiceItem.defaultProps = {
  type: 'bank'
};

export default ServiceItem;
