import React from "react"
import Layout from "../components/layout"
import "../styles/layout.css"
import Empresa from "../components/empresa"
import Contactenos from "../components/contactenos"
import Cover from "../components/cover"
import MissionAndVission from "../components/mission-and-vision"
import AreasEspecialidad from "../components/areas-especialidad"
import ServicesSection from "../components/services-section"
import WeArePart from "../components/we-are-part"
import SEO from "../components/seo"
import { LegalModal } from "../components/legal-modal"

const IndexPage = () => (
  <Layout>
    <LegalModal />
    <Cover />
    <SEO title="Inicio" />
    <div style={{ marginTop: "80px" }} />
    <Empresa />
    <MissionAndVission />
    <div style={{ marginTop: "80px" }} />
    <AreasEspecialidad />
    <div style={{ marginTop: "80px" }} />
    <Contactenos />
    <div style={{ marginTop: "80px" }} />
    <ServicesSection />
    <div style={{ marginTop: "80px" }} />
    <WeArePart />
  </Layout>
)

export default IndexPage
