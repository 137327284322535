import React, { Component } from "react"
import styled from "styled-components"
import Zoom from 'react-reveal/Zoom'
import Resources from '../../resources/afisofom-resources.json'
import { useSiteContent } from "../hooks/use-site-content"
const AEConf = Resources.areas_esp
// MAIN WRAPPER
const AEWrapper = styled.div`
  font-family: var(--font-regular);
`

// TOP BAR
const TopBar = styled.div`
  color: var(--main-color);
`

const AETitle = styled.h2`
  color: var(--main-color-text);
  font-size: var(--section-title-size);
  font-family: var(--font-bold);
  line-height: normal;
`

// BUTTONS
const ButtonSect = styled.div`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1em;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 1.5em;

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 401px) and (max-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2em;
        grid-template-rows: repeat(6, 1fr);
        grid-row-gap: 1.5em;
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 400px) {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 2em;
        grid-template-rows: repeat(12, 1fr);
        grid-row-gap: 1.5em;
    }
`

const FalseButton = styled.div`
  border-bottom: 2px solid var(--main-color-2);
  padding: 0.5em 19px;
  box-sizing: border-box;
  display: grid;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  transition: background-color 0.5s, color 0.5s;
  &:hover {
    background-color: var(--main-color-2);
    color: white;
  }
  @media only screen and (max-width: 700px) {
  border-bottom: 4px solid var(--main-color-2);
  }
`
const BtnTxt = styled.span`
  text-align: center;
  align-self: center;
  justify-self: center;
`

const MakeButtons = ({areas}) => {
  return areas.map((text, number) => {
    return (
      <FalseButton key={"btn_key_" + number}>
        <BtnTxt>{text}</BtnTxt>
      </FalseButton>
    )
  })
}

class AreasEspecialidad extends Component {
  render() {
    const areas_esp = this.props.siteContent.areas_esp.areas
    return (
      <div className="container"
            id="areas-especialidad">
        <AEWrapper>
          <Zoom>
            <TopBar>
              <AETitle>{AEConf.titulo}</AETitle>
            </TopBar>
            <ButtonSect>
              <MakeButtons areas={areas_esp}/>
            </ButtonSect>
          </Zoom>
        </AEWrapper>
      </div>
    )
  }
}

export default props => {
  const SiteContent = useSiteContent();
  return (
    <AreasEspecialidad siteContent={SiteContent} {...props} />
  )
}

