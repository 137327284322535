import React, { Component } from "react"
import styled from "styled-components"

import Resources from "../../resources/afisofom-resources.json"
import EmpSVG1 from "../images/svg/empresa1.svg"
import EmpSVG2 from "../images/svg/empresa2.svg"
import EmpSVG3 from "../images/svg/empresa3.svg"
import Fade from "react-reveal/Fade"
import { useSiteContent } from "../hooks/use-site-content"

const EmpConf = Resources.empresa

const EmpWrapper = styled.div`
  font-family: var(--font-normal);
`

// MAIN GRID //
const ButtonImgSect = styled.div`
  display: grid;
  --divider-padding: 3em;
  --item-width: 8em;
  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 375px) and (max-width: 991px) {
    grid-template-rows: 0.5fr 1fr;
    --divider-padding: 0;
  }

  @media only screen and (max-width: 375px) {
    grid-template-rows: 1fr 1.2fr;
    --divider-padding: 0;
  }
`

const TextWrapper = styled.div`
  padding-right: var(--divider-padding);

  @media only screen and (min-width: 992px) {
    border-right: 1px solid var(--main-color-2);
  }
`

// TEXT //
const EmpTitle = styled.h1`
  color: var(--main-color-text);
  margin-bottom: 0;
  font-family: var(--font-bold);
  font-size: var(--section-title-size);
`

const EmpPara = styled.p`
  margin-top: 0.8em;
  line-height: 2em;
`

// BUTTONS //
const ImgButtonWrapper = styled.div`
  padding-left: var(--divider-padding);
  display: grid;

  svg {
    width: var(--item-width);

    @media only screen and (max-width: 991px) {
      grid-column: 1;
      justify-self: center;
      align-self: center;
    }
  }

  @media only screen and (min-width: 992px) {
    margin: 3em 0em;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    justify-items: center;
    align-items: center;
  }

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 1em;
  }
`

const ButtonText = styled.span`
  font-family: var(--font-bold);
  text-align: center;
  align-self: start;
  width: calc(var(--item-width) + 1em);

  line-height: 1.7em;
  @media only screen and (max-width: 991px) {
    grid-column: 2;
    width: auto;
    align-self: center;
    justify-self: left;
    text-align: left;
  }
`

const ButtonText1 = styled(ButtonText)`
  @media only screen and (max-width: 991px) {
    grid-row: 1;
  }
`
const ButtonText2 = styled(ButtonText)`
  @media only screen and (max-width: 991px) {
    grid-row: 2;
  }
`
const ButtonText3 = styled(ButtonText)`
  @media only screen and (max-width: 991px) {
    grid-row: 3;
  }
`

// POPUPS
const PopupDivHolder = styled.div``

const BGDiv = styled.div`
  background-color: var(--main-color-text);
  filter: opacity(80%);
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
`

const PopupDiv = styled.div`
  position: fixed;
  width: 75vw;
  top: 20vh;
  left: 7vw;
  background-color: var(--main-color);
  border-radius: 20px;
  color: var(--main-background);
  padding: 3em;
  z-index: 10001;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    min-width: 66%;
    max-width: 66%;
    margin: 0 auto;
    min-height: 350px;
    max-height: 350px;
    overflow-y: scroll;
    padding: 30px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    min-width: 80%;
    max-width: 80%;
    margin: 0 auto;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
    padding: 30px;
  }
`

const PopupTitle = styled.h2`
  font-family: var(--font-bold);
  margin-bottom: 2em;
`

const PopupText = styled.p`
  line-height: 1.8em;
  margin-bottom: 5em;
`

class Empresa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBox: false,
      popupTitle: "",
      popupText: "",
    }
  }

  toggleBox(boxNumber) {
    let title, content
    switch (boxNumber) {
      case 1:
        title = EmpConf.popup_1.title
        content = EmpConf.popup_1.text
        break
      case 2:
        title = EmpConf.popup_2.title
        content = EmpConf.popup_2.text
        break
      case 3:
        title = EmpConf.popup_3.title
        content = EmpConf.popup_3.text
        break
      default:
        title = "ERROR"
        content = "ERROR, BAD POPUP NUMBER"
        break
    }
    this.setState(
      {
        showBox: !this.state.showBox,
        popupTitle: title,
        popupText: content,
      },
      function () {
        this.killScroll()
      }
    )
  }

  killScroll() {
    if (this.state.showBox) {
      document.body.style =
        "overflow-y:hidden; position: relative; margin-right: var(--widthReflow);"
    } else {
      document.body.style =
        "overflow-y:visible; position: static; margin-right: 0;"
    }
  }

  render() {
    return (
      <div
        className="container"
        id="empresa-container"
        style={{ overflowX: "hidden" }}
      >
        <EmpWrapper>
          {this.state.showBox ? (
            <PopupDivHolder onClick={() => this.toggleBox()}>
              <PopupDiv>
                <PopupTitle>{this.state.popupTitle}</PopupTitle>
                <PopupText>{this.state.popupText}</PopupText>
              </PopupDiv>
              <BGDiv />
            </PopupDivHolder>
          ) : null}
          <ButtonImgSect>
            <Fade left>
              <TextWrapper>
                <EmpTitle>{EmpConf.titulo}</EmpTitle>
                <EmpPara>{this.props.siteContent.empresa.description}</EmpPara>
              </TextWrapper>
            </Fade>
            <Fade right>
              <ImgButtonWrapper>
                <EmpSVG1 />
                <EmpSVG2 />
                <EmpSVG3 />
                <ButtonText1>
                  {this.props.siteContent.empresa.label_1}
                </ButtonText1>
                <ButtonText2>
                  {this.props.siteContent.empresa.label_2}
                </ButtonText2>
                <ButtonText3>
                  {this.props.siteContent.empresa.label_3}
                </ButtonText3>
              </ImgButtonWrapper>
            </Fade>
          </ButtonImgSect>
        </EmpWrapper>
      </div>
    )
  }
}

export default props => {
  const SiteContent = useSiteContent()
  return <Empresa siteContent={SiteContent} {...props} />
}
